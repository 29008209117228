import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AnalistaService } from "./api-analista.service";
import { RegisterAccess } from "../../app/shared/Entities/RegisterAccess";

@Injectable({
  providedIn: 'root'
})

export class AnalistaDataService {

  constructor(private api: AnalistaService) {
  }

  public GetAnalista(): Observable<any> {
    return this.api.GetAnalista();
  }

  public RegistraAcesso(signature: RegisterAccess): Observable<any> {
    return this.api.RegistraAcesso(signature);
  }
}
