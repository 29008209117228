import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NbAuthJWTToken, NbAuthService } from "@nebular/auth";

@Injectable({
  providedIn: 'root'
})
export class RequestTokenService {

  private token: NbAuthJWTToken = null;

  constructor(private authService: NbAuthService) {
    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.token = token; // here we receive a payload from the token and assigns it to our `user` variable
      }
    });

   }

  createAuthorizationHeader(): HttpHeaders {
    if (!this.token || !this.token.isValid()) {
      throw new Error('Token inválido ou não disponível.');
    }

    return new HttpHeaders().set('Authorization', `${this.token.getValue()}`);
  }
}
