export class RegisterAccess {

  idEmployee: string;
  userName: string;
  email: string;
  ip: string;
  countryName: string;
  regionCode: string;
  regionName: string;
  city: string;
  latitude: number;
  longitude: number;
  statusLocalizacao: string;
  birthDate: string;
  negocioId: string = null;
  nomeCliente: string = '';

  constructor(values: Object = {}) {
      Object.assign(this, values);
  }
}
