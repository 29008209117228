import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { RequestTokenService } from "../request-token.service";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { RegisterAccess } from "../../app/shared/Entities/RegisterAccess";

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})

export class AnalistaService {

  constructor(private http: HttpClient,
    private requestToken: RequestTokenService
  ) {}

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // Erro no cliente
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Erro no servidor
      errorMessage = `Server Error: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
  public GetAnalista(): Observable<any>{
    const options = {
      headers: this.requestToken.createAuthorizationHeader(),
      withCredentials: true // Garante que cookies/autenticação sejam enviados
    };

    return this.http.get<any>(`${API_URL}v1/Analista/GetAnalista`, options)
    .pipe(
      catchError(this.handleError)
    )
  }

  public RegistraAcesso(signature: RegisterAccess): Observable<any>{
    const options = {
      headers: this.requestToken.createAuthorizationHeader(),
      withCredentials: true // Garante que cookies/autenticação sejam enviados
    };

    return this.http.post<any>(`${API_URL}v1/Analista/RegistraAcesso`, signature, options)
    .pipe(
      catchError(this.handleError)
    )
  }
}
