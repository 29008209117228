import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RegisterAccess } from "../Entities/RegisterAccess";
import { IpInfoResponse } from "../interface/IpInfoResponse";
import { AnalistaDataService } from "../../../services/AnalistaController/analista-data.service";

@Injectable({
  providedIn: 'root',
})
export class RegistrarAcessoService {
  private regAcesso: RegisterAccess = new RegisterAccess();

  constructor(private http: HttpClient,
    private analistaService: AnalistaDataService
  ){

  }

  public registerUserIP(cliente: any) {
    this.http.get<IpInfoResponse>('https://api.ipify.org/?format=json')
      .subscribe(data => {

        this.regAcesso.ip = data.ip;

        if (cliente != null) {
          this.regAcesso.negocioId = cliente.negocio.id;
          this.regAcesso.nomeCliente = cliente.nomeCliente;
        }

        let userIp = data.ip;

        this.registerUserIpLocation(userIp);

      }, error => {

      });
  }

  private registerUserIpLocation(userIp: string) {

    this.http.get<IpInfoResponse>('https://ipapi.co/' + userIp + '/json/')
      .subscribe(data => {

        this.regAcesso.countryName = data.country_name;
        this.regAcesso.regionCode = data.region_code;
        this.regAcesso.regionName = data.region;
        this.regAcesso.city = data.city;
        this.regAcesso.latitude = Number.parseFloat(data.latitude);
        this.regAcesso.longitude = Number.parseFloat(data.longitude);
        this.regAcesso.statusLocalizacao = 'Posição obtida';

        //Quarta Etapa - Localização do IP
        // this.registerAccess().subscribe(dados => null);

        this.analistaService.RegistraAcesso(this.regAcesso).subscribe(
          value => {

          },
          value => {

        });
      }, error => {
        //Quarta Etapa - Localização do IP
        // this.registerAccess().subscribe(dados => null);
      });
  }
}
